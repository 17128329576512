<template>
  <div>
    <app-page-detail page-title="Detail Vendor" page-toolbar-title="Detail Vendor">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()"></action-button>
      </template>
      <v-row>
        <v-col cols="12" lg="12">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="vendor.category" placeholder="Category" label="Category" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.name" placeholder="Name" label="Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.business_fields" placeholder="Business Fields" label="Business Fields" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.npwp" placeholder="Business Fields" label="Business Fields" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="vendor.phone_no" placeholder="Phone No." label="Phone No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.email" placeholder="E-Mail" label="E-Mail" hide-details outlined class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.website" placeholder="Website" label="Website" hide-details outlined class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Address" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="vendor.country_name" placeholder="Country Name" label="Country Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.province_name" placeholder="Province Name" label="Province Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.regency_name" placeholder="Regency Name" label="Regency Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.district_name" placeholder="District Name" label="District Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.post_code" placeholder="Post Code" label="Post Code" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-textarea dense readonly v-model="vendor.address" placeholder="Address" label="Address" outlined rows="3" hide-details class="mb-2"></v-textarea>
                    <v-textarea dense readonly v-model="vendor.address2" placeholder="Address2" label="Address2" outlined rows="3" hide-details class="mb-2"></v-textarea>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="PIC" class="mb-3" :loading="loading">
                <v-row class="mb-3">
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="vendor.pic_name" placeholder="PIC Name" label="PIC Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.pic_phone_no" placeholder="PIC Phone No." label="PIC Phone No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.pic_email" placeholder="PIC E-Mail" label="PIC E-Mail" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.pic_position" placeholder="PIC Position" label="PIC Position" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="vendor.username" placeholder="Username" label="Username" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.password" placeholder="Password" label="Password" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Documents" class="mb-3" :loading="loading">
                <v-row class="mb-3">
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="vendor.npwp" placeholder="NPWP" label="NPWP" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.npwp_src" placeholder="NPWP File" label="NPWP File" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.npwp_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.ktp_src" placeholder="KTP" label="KTP" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.ktp_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.company_profile_src" placeholder="Company Profile" label="Company Profile" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.company_profile_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.nib_src" placeholder="NIB" label="NIB" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.nib_src)" append-icon="mdi-download"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="vendor.catalog_src" placeholder="Catalog" label="Catalog" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.catalog_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.certificate_src" placeholder="Cerificate" label="Cerificate" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.certificate_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.licensing_document_src" placeholder="Licensing" label="Licensing" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.licensing_document_src)" append-icon="mdi-download"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Info" class="mb-3" :loading="loading">
                <v-row class="mb-3">
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="vendor.registration_date" placeholder="Registration Date" label="Registration Date" type="datetime" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="vendor.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="vendor.nav_vendor_no_" placeholder="NAV Vendor No." label="NAV Vendor No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="user.is_active" v-if="(typeof user.is_active != 'undefined')" placeholder="User Active" label="User Active" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </app-page-detail>

  </div>
</template>

<script>
import ActionButton from './components/ActionButton.vue';
export default {
  components: {
    ActionButton
  },
  data() {
    return {
      id: null,
      loading: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_approve: false,
      loading_cancel: false,
      dialog: false,
      modal1: false,
      tab: null,
      data_detail: {},
      vendor: {},
      user: {},
      notFound: false,
    };
  },
  watch: {
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    // showDialog() {
    //   this.getDetail(true);
    // },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "vendor/detail");

      this.showLoadingOverlay(true);


      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("vendor/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.vendor = res.data.data.vendor;
          this.user = res.data.data.user;
          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {

          if (error.response.status == 404){
            this.redirect404()
          }

          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("vendor", "view", true);

    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
